<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <template v-if="items.length > 0">
      <v-row>
        <v-col>
          <h2>
            {{ formatNumber(items.length) }} Recent Appointments Set
          </h2>
        </v-col>
      </v-row>
    </template>
    <v-row dense style="padding-top: 0; padding-bottom: 0;">
      <v-alert
        dense
        color="blue"
        elevation="5"
        dark
        style="padding-top: 0; padding-bottom: 0;"
      >
        <strong>Sales Customers</strong>
      </v-alert>
      &nbsp;
      <v-alert
        dense
        color="green"
        elevation="5"
        dark   
        style="padding-top: 0; padding-bottom: 0;"     
      >
        <strong>Service Customers</strong>
      </v-alert>
    </v-row>
    <template>
      <div>
        <v-sheet
          tile
          height="54"
          class="d-flex"
        >
          <v-btn
            icon
            class="ma-2"
            @click="$refs.calendar.prev()"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-toolbar
            flat
          >
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
          </v-toolbar>
          <v-select
            v-model="type"
            :items="types"
            dense
            outlined
            hide-details
            class="ma-2"
            label="type"
          ></v-select>
          <v-spacer></v-spacer>
          <v-btn
            icon
            class="ma-2"
            @click="$refs.calendar.next()"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-sheet>
        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            v-model="value"
            :weekdays="weekday"
            :type="type"
            :events="events"
            :event-overlap-mode="mode"
            :event-overlap-threshold="30"
            :event-color="getEventColor"
            @change="getEvents"
            @click:event="showCustomer"
          ></v-calendar>
        </v-sheet>
      </div>
    </template>

    <v-row dense>
      <v-col>
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
          </v-card-title>
          <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="items"
            :search="search"
            :items-per-page="50"
            :sort-by="['name']"
          >
            <template v-slot:item.name="{ item }">
              <router-link
                style="cursor: pointer; color: blue; text-decoration: underline"
                :to="{ name: 'Customer', params: { campaign_id: item.campaign_uuid, customer_id: item.uuid } }"
              >
                {{ item.first_name }} {{ item.last_name }}
              </router-link>
            </template>
            <template #item.appt_date="{value}">
              {{ formatIsoDate(value, true) }}
            </template>
            <template #item.appt_set_at="{value}">
              {{ formatIsoDate(value, true) }}
            </template>
            <template #item.phone="{value}">
              {{ formatPhoneNumber(value) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="#FF5E40"
        dark
      >
        <v-card-text>
          {{ loadingMessage }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showErrorDialog"
      persistent
      max-width="600"
      @keydown.esc="showErrorDialog = false"
    >
      <error-message
        :error="error"
        @closeErrorDialog="closeErrorDialog"
      />
    </v-dialog>
  </v-container>
</template>
<script>
  import NetworkService from '@/services/NetworkService.js'
  import ErrorMessage from '@/components/ErrorMessage.vue'
  import ChatovateService from '@/services/ChatovateService.js'
  import { mapGetters } from 'vuex'
  import { pick } from '@/mixins/exportMixin.js'
  import { formatterMixin } from '@/mixins/formatterMixin.js'
  import { navigationMixin } from '@/mixins/navigationMixin.js'
  import titleMixin from '@/mixins/titleMixin.js'
  import { VBtn } from 'vuetify/lib'
  export default {
    name: 'ChatovateAppointments',
    components: {
      ErrorMessage,
      CalendarBtn: {
        extends: VBtn,

        props: {
          color: {
            type: String,
            default: 'secondary',
          },
          minWidth: {
            type: Number,
            default: 0,
          },
          rounded: {
            type: Boolean,
            default: true,
          },
        },

        computed: {
          classes () {
            return {
              ...VBtn.options.computed.classes.call(this),
              'mx-1': true,
              'text-lowercase': true,
            }
          },
        },
      },
    },
    filters: {
      capitalize: function (value) {
        if (!value) return ''
        value = value.toString()
        return value.charAt(0).toUpperCase() + value.slice(1)
      },
    },
    mixins: [formatterMixin, navigationMixin, titleMixin],
    data () {
      return {
        loadingMessage: 'Loading',
        showErrorDialog: false,
        campaignId: null,
        items: [],
        search: '',
        loading: true,
        error: '',
        expanded: [],
        headers: [
          { text: 'Name', value: 'name', align: 'center' },
          { text: 'Phone', value: 'phone', align: 'center' },
          { text: 'Email', value: 'email', align: 'center' },
          { text: 'Customer Type', value: 'customer_type', align: 'center' },
          { text: 'Appointment Date', value: 'appt_date', align: 'center' },
          { text: 'Appointment Set At', value: 'appt_set_at', align: 'center' },
        ],
        type: 'month',
        types: ['month', 'week', 'day'],
        mode: 'stack',
        weekday: [0, 1, 2, 3, 4, 5, 6],
        value: '',
        events: [],
      }
    },
    computed: {
      parsedItems () {
        const parsedHeaders = this.headers.map(h => h.value)
        if (this.items) {
          return this.items.map(i => pick(i, parsedHeaders))
        }
        return []
      },
      ...mapGetters(['accountId', 'accountName']),
    },
    watch: {
      accountId () {
        this.loadCustomers()
      },
      watch: {
      },
    },
    mounted () {
      this.$refs.calendar.checkChange()
    },
    created () {
      this.loadCustomers()
    },
    methods: {
      closeErrorDialog: function () {
        this.showErrorDialog = false
      },
      loadCustomers: function () {
        this.items = []
        this.loading = true
        ChatovateService.getAppointments()
          .then(response => {
            this.items = response.data
            this.items.forEach(item => {
              item.appointmentTime = new Date(Date.parse(item.appt_date))
            })  
            this.getEvents()          
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      getEvents () {
        const events = []

        if (this.items && this.items.length > 0) {
          for (let i = 0; i < this.items.length; i++) {
            events.push({
              name: this.items[i].first_name + ' ' + this.items[i].last_name,
              start: this.items[i].appointmentTime,
              end: this.items[i].appointmentTime,
              color: this.items[i].customer_type === 'Sales' ? 'blue' : 'green',
              timed: true,
              campaignId: this.items[i].campaign_uuid,
              customerId: this.items[i].uuid,
            })
          }

          this.events = events
        }
      },
      getEventColor (event) {
        return event.color
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      }, 
      showCustomer(params) {
        this.$router.push({ name: 'Customer', params: { campaign_id: params.event.campaignId, customer_id: params.event.customerId} }) 
      }
    },
  }
</script>